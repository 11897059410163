// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cloud-js": () => import("./../../../src/pages/cloud.js" /* webpackChunkName: "component---src-pages-cloud-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-contract-js": () => import("./../../../src/pages/contract.js" /* webpackChunkName: "component---src-pages-contract-js" */),
  "component---src-pages-crm-js": () => import("./../../../src/pages/crm.js" /* webpackChunkName: "component---src-pages-crm-js" */),
  "component---src-pages-custom-luseis-js": () => import("./../../../src/pages/custom-luseis.js" /* webpackChunkName: "component---src-pages-custom-luseis-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-erp-js": () => import("./../../../src/pages/erp.js" /* webpackChunkName: "component---src-pages-erp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-logistikes-efarmoges-kai-misthodosia-js": () => import("./../../../src/pages/logistikes-efarmoges-kai-misthodosia.js" /* webpackChunkName: "component---src-pages-logistikes-efarmoges-kai-misthodosia-js" */),
  "component---src-pages-megales-epixeiriseis-js": () => import("./../../../src/pages/megales-epixeiriseis.js" /* webpackChunkName: "component---src-pages-megales-epixeiriseis-js" */),
  "component---src-pages-mikromesaies-epixeiriseis-js": () => import("./../../../src/pages/mikromesaies-epixeiriseis.js" /* webpackChunkName: "component---src-pages-mikromesaies-epixeiriseis-js" */),
  "component---src-pages-presentation-js": () => import("./../../../src/pages/presentation.js" /* webpackChunkName: "component---src-pages-presentation-js" */),
  "component---src-pages-startups-js": () => import("./../../../src/pages/startups.js" /* webpackChunkName: "component---src-pages-startups-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-why-us-js": () => import("./../../../src/pages/why-us.js" /* webpackChunkName: "component---src-pages-why-us-js" */)
}

